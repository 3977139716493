<template>
  <c-box>
    <template v-if="step.type === 'ELEMENT'">
      <c-flex
        v-if="
          step.title !== 'Team' &&
          step.title !== 'Elevator Pitch' &&
          step.title !== 'Problem Interview'
        "
        bg="#F8F8F8"
        borderRadius="8px 8px 0 0"
        w="50%"
        h="50px"
        align="center"
        justify="space-between"
        px="4"
      >
        <ProgressBar
          :value="progressStages[progress]"
          :valueOutside="true"
          :height="'10px'"
          v-chakra="{
            flex: 1,
          }"
        />
      </c-flex>
      <c-box
        minHeight="200px"
        borderColor="#2D2D2D"
        borderWidth="1px"
        borderRadius="0 0 8px 8px"
        p="8"
      >
        <c-flex align="center" justify="space-between" mb="3">
          <c-text fontSize="2xl" fontWeight="700">
            {{ step.title }}
          </c-text>
          <c-text color="gray.400" fontSize="xl" fontWeight="500">
            {{ step.time }}
          </c-text>
        </c-flex>
        <c-list maxWidth="70%" mb="6">
          <c-list-item
            v-for="(description, index) in step.descriptions"
            :key="index"
          >
            <c-flex mb="2">
              <svg
                v-chakra="{
                  w: '4',
                  h: '4',
                  mr: '2',
                }"
              >
                <use href="@/assets/icons/icon-tools.svg#tools"></use>
              </svg>
              <c-text fontSize="xs" flex="1" color="gray.400">
                {{ description }}
              </c-text>
            </c-flex>
          </c-list-item>
        </c-list>
        <c-flex justify="flex-end">
          <c-pseudo-box
            as="a"
            display="flex"
            alignItems="center"
            cursor="pointer"
            :_hover="{
              textDecoration: 'none',
            }"
            @click="$emit('goToStep')"
          >
            <c-text color="vc-orange.400" fontSize="lg" fontWeight="600" mr="2">
              Start
            </c-text>
            <svg
              v-if="progress === 'DONE'"
              v-chakra="{
                w: '8',
                h: '8',
              }"
            >
              <use href="@/assets/icons/circle-tick-fill.svg#circle-tick"></use>
            </svg>
            <svg
              v-else
              v-chakra="{
                w: '12',
                h: '12',
              }"
            >
              <use href="@/assets/icons/play-circle-fill.svg#play-circle"></use>
            </svg>
          </c-pseudo-box>
        </c-flex>
      </c-box>
    </template>
    <template v-else>
      <c-flex
        bg="#F8F8F8"
        borderRadius="8px 8px 0 0"
        w="50%"
        h="50px"
        align="center"
        justify="space-between"
        px="4"
      >
        <ProgressBar
          :value="growthFrameworkCompletion[step.key].totalPercentage"
          :valueOutside="true"
          :height="'10px'"
          v-chakra="{
            flex: 1,
          }"
        />
      </c-flex>
      <c-flex
        minHeight="250px"
        borderColor="#2D2D2D"
        borderWidth="1px"
        borderRadius="0 0 8px 8px"
        p="8"
      >
        <c-box w="40%" borderRightWidth="1px" borderColor="#79889233" pr="8">
          <c-flex align="center" justify="space-between" mb="3">
            <c-text fontSize="2xl" fontWeight="700">
              {{ step.title }}
            </c-text>
            <c-text color="gray.400" fontSize="xl" fontWeight="500">
              {{ step.time }}
            </c-text>
          </c-flex>
          <c-list maxWidth="70%" mb="6">
            <c-list-item
              v-for="(description, index) in step.descriptions"
              :key="index"
            >
              <c-flex mb="2">
                <svg
                  v-chakra="{
                    w: '4',
                    h: '4',
                    mr: '2',
                  }"
                >
                  <use href="@/assets/icons/icon-tools.svg#tools"></use>
                </svg>
                <c-text fontSize="xs" flex="1" color="gray.400">
                  {{ description }}
                </c-text>
              </c-flex>
            </c-list-item>
          </c-list>
          <c-flex justify="flex-end">
            <c-pseudo-box
              as="a"
              display="flex"
              alignItems="center"
              cursor="pointer"
              :_hover="{
                textDecoration: 'none',
              }"
              @click="$emit('goToPage', { page: step, pageIndex: stepIndex })"
            >
              <c-text
                color="vc-orange.400"
                fontSize="lg"
                fontWeight="600"
                mr="2"
              >
                Start
              </c-text>

              <svg
                v-if="
                  growthFrameworkCompletion[step.key].totalPercentage === 100
                "
                v-chakra="{
                  w: '8',
                  h: '8',
                }"
              >
                <use
                  href="@/assets/icons/circle-tick-fill.svg#circle-tick"
                ></use>
              </svg>
              <svg
                v-else
                v-chakra="{
                  w: '12',
                  h: '12',
                }"
              >
                <use
                  href="@/assets/icons/play-circle-fill.svg#play-circle"
                ></use>
              </svg>
            </c-pseudo-box>
          </c-flex>
        </c-box>
        <c-box flex="1" ml="8">
          <c-text fontWeight="500" mb="2"> Content </c-text>
          <c-grid
            w="100%"
            :template-columns="{ base: '1fr', lg: 'repeat(3, 1fr)' }"
            gap="8"
          >
            <c-flex
              align="center"
              v-for="(pageStep, index) in step.tour_steps"
              :key="pageStep.target"
              w="100%"
            >
              <c-text fontSize="xl" color="gray.300" fontWeight="500" mr="3">
                {{ index &lt; 10 ? '0' : '' }}{{ index + 1 }}
              </c-text>
              <c-box mr="3">
                <c-text
                  fontSize="10px"
                  fontWeight="500"
                  color="gray.300"
                  mb="1"
                >
                  {{ pageStep.time }}
                </c-text>
                <c-text fontSize="sm" fontWeight="600">
                  {{ pageStep.title }}
                </c-text>
              </c-box>
              <c-pseudo-box
                as="a"
                display="flex"
                alignItems="center"
                cursor="pointer"
                :_hover="{
                  textDecoration: 'none',
                }"
                ml="auto"
                @click="
                  $emit('goToStep', { parentTour: step, stepIndex: index })
                "
              >
                <svg
                  v-if="
                    growthFrameworkCompletion[step.key][pageStep.key] === 'DONE'
                  "
                  v-chakra="{
                    w: '8',
                    h: '8',
                  }"
                >
                  <use
                    href="@/assets/icons/circle-tick-fill.svg#circle-tick"
                  ></use>
                </svg>
                <svg
                  v-else
                  v-chakra="{
                    w: '8',
                    h: '8',
                  }"
                >
                  <use
                    href="@/assets/icons/play-circle-fill.svg#play-circle"
                  ></use>
                </svg>
              </c-pseudo-box>
            </c-flex>
          </c-grid>
        </c-box>
      </c-flex>
    </template>
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex';

import ProgressBar from '@/views/App/components/ProgressBar.vue';

export default {
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    stepIndex: {
      type: Number,
    },
    progress: {
      type: String,
      default: 'NOT_DONE',
    },
  },
  components: {
    ProgressBar,
  },
  data() {
    return {
      progressStages: {
        NOT_DONE: 0,
        IN_PROGRESS: 25,
        HALF_WAY: 50,
        ALMOST_DONE: 75,
        DONE: 100,
      },
    };
  },
  computed: {
    ...mapGetters('company', ['growthFrameworkCompletion']),
  },
};
</script>

<style></style>
