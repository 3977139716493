<template>
  <div class="sdg-fields">
    <div class="sdg-fields__goals">
      <div class="sdg-fields__goals__count">
        <span>{{ selectedGoals.length }}</span>
        Goals
      </div>
      <c-box my="1.5rem" display="flex" flexDirection="column">
        <div class="sdg-fields__goals__list">
          <div
            class="sdg-fields__goals__list-item"
            v-for="(selectedGoal, index) in selectedGoals"
            :key="index"
          >
            <img :src="selectedGoal.image" />
          </div>
        </div>
        <c-popover :placement="'right'" v-if="!disabled">
          <c-popover-trigger>
            <c-link class="sdg-fields__goals__list__add">
              <svg>
                <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
              </svg>
              {{ selectedGoals.length ? 'Modify' : 'Add' }} Goals
            </c-link>
          </c-popover-trigger>
          <c-popover-content
            z-index="4"
            width="250px"
            maxHeight="250px"
            overflowY="scroll"
          >
            <c-popover-close-button />
            <c-popover-header fontSize="xs" fontWeight="500"
              >Sustainable Development Goals</c-popover-header
            >
            <c-popover-body padding="20px">
              <c-checkbox-group
                :value="checkedGoals"
                @change="onCheckListChange"
                v-if="sdgs.length"
              >
                <c-checkbox
                  v-for="goal in sdgs"
                  :key="goal.id"
                  :value="goal.id + ''"
                  >{{ goal.name }}</c-checkbox
                >
              </c-checkbox-group>
            </c-popover-body>
          </c-popover-content>
        </c-popover>
      </c-box>
    </div>
    <div class="sdg-fields__questions">
      <div>
        <c-text as="span" color="#ef5323" fontSize="14px" v-if="csaved"
          ><b>Saved successfully 👍</b></c-text
        >

        <EditableText
          :title="'How do you contribute to reach these goals?'"
          type="sdg"
          v-model="contribution"
          @updateDescription="updateContribution"
          :disabled="disabled"
          :showTitle="true"
        />
      </div>

      <div>
        <c-text as="span" color="#ef5323" fontSize="14px" v-if="esaved"
          ><b>Saved successfully 👍</b></c-text
        >

        <EditableText
          :title="'How will you evaluate your contribution?'"
          type="sdg2"
          v-model="evaluation"
          @updateDescription="updateEvaluation"
          :disabled="disabled"
          :showTitle="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EditableText from '../EditableText';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    sdgValues: {
      type: Array,
      default: () => [],
    },
    evaluation: {
      type: String,
      default: '',
    },
    contribution: {
      type: String,
      default: '',
    },
  },
  components: {
    EditableText,
  },
  data() {
    return {
      selectedGoals: [],
      csaved: false,
      esaved: false,
    };
  },
  computed: {
    ...mapState('config', {
      sdgs: (state) => state.sdgs,
    }),
    checkedGoals() {
      return this.selectedGoals.map((goal) => goal.id + '');
    },
  },
  created() {
    if (this.sdgValues.length) {
      this.setSelectedGoals();
    }
  },
  watch: {
    sdgValues() {
      this.setSelectedGoals();
    },
  },
  methods: {
    onCheckListChange(checkedGoalsIds) {
      const goals = checkedGoalsIds.map((id) => {
        return this.sdgs.find((goal) => goal.id == id);
      });
      this.selectedGoals = goals;
      this.$emit('updateSdgs', this.selectedGoals);
    },
    setSelectedGoals() {
      this.selectedGoals = this.sdgValues
        .map((value) => {
          return this.sdgs.find((goal) => goal.id == value.SDGId);
        })
        .sort((a, b) => a.position - b.position);

      // add this.selectedGoals to session storage
      sessionStorage.setItem('selectedSDG', JSON.stringify(this.selectedGoals));
      sessionStorage.setItem('contribution', this.contribution);
    },
    updateContribution(value) {
      this.contribution = value;
      sessionStorage.setItem('contribution', value);
      this.$emit('updateField', { value, key: 'contribution' });
      this.csaved = true;

      setTimeout(() => {
        this.csaved = false;
      }, 3000);
    },
    updateEvaluation(value) {
      this.evaluation = value;
      this.$emit('updateField', { value, key: 'evaluation' });
      this.esaved = true;

      setTimeout(() => {
        this.esaved = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.sdg-fields {
  &__goals {
    @apply my-6;
    &__count {
      span {
        @apply text-6xl;
        color: $color-orange;
      }
    }
    &__list {
      @apply my-5 flex flex-wrap;
      &-item {
        @apply mr-4 mt-7;
        width: 100px;
        height: 100px;
        border-radius: 11px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__add {
        @apply h-full flex flex-col mx-4 font-semibold items-center justify-center text-center;
        color: $color-orange;
        width: 100px;
        svg {
          @apply mb-3;
          width: 20px;
          height: 20px;
          fill: $color-orange;
        }
      }
    }
  }
  &__questions {
    @apply grid my-11;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  @screen lg {
    &__questions {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
