<template>
  <div class="progress-bar" :class="{ 'progress-bar': valueOutside }">
    <c-text
      as="span"
      color="vc-green.500"
      fontWeight="600"
      v-if="valueOutside"
      mr="2"
    >
      {{ value > 100 ? 100 : value }}%
    </c-text>
    <c-progress
      :height="height"
      borderRadius="4px"
      color="vc-green"
      :value="value"
    >
      <span v-if="!valueOutside">{{ value > 100 ? 100 : value }}%</span>
    </c-progress>
  </div>
</template>

<script>
export default {
  props: ['value', 'height', 'valueOutside'],
};
</script>

<style lang="scss">
.progress-bar {
  @apply flex items-center;
  div[role='progressbar'] {
    position: relative;
    @apply flex items-center justify-center relative;
    span {
      @apply font-semibold;
      color: #fff;
    }
  }
}
</style>
